var typeText = document.querySelector("#feature-text")
var textToBeTyped = "a software engineer"
var textToBeTypedArr = ["Amazon", "eBay", "Ihren Onlineshop", "Etsy"]
var index = 0, isAdding = true, textToBeTypedIndex = 0

function playAnimation() {
  setTimeout(function () {
    // set the text of typeText to a substring of the text to be typed using index.
    typeText.innerText = textToBeTypedArr[textToBeTypedIndex].slice(0, index)
    if (isAdding) {
      // adding text
      if (index > textToBeTypedArr[textToBeTypedIndex].length) {
        // no more text to add
        isAdding = false
        typeText.classList.add("showAnim")
        //break: wait 2s before playing again
        setTimeout(function () {
          typeText.classList.remove("showAnim")
          playAnimation()
        }, 2000)
        return
      } else {
        // increment index by 1
        index++
      }
    } else {
      // removing text
      if (index === 0) {
        // no more text to remove
        isAdding = true
        //switch to next text in text array
        textToBeTypedIndex = (textToBeTypedIndex + 1) % textToBeTypedArr.length
      } else {
        // decrement index by 1
        index--
      }
    }
    // call itself
    playAnimation()
  }, isAdding ? 110 : 60)
}
// start animation
playAnimation()


/**
 * Form submit
 */
 document.querySelector("form#formular").addEventListener("submit", function(e){
  e.preventDefault();
  
  var error = false;

  $(this).find('.is-invalid').removeClass('is-invalid');

  // Produktanzahl
  var produktanzahl = $("select#produktanzahl").val();

  // Beschreibung
  var beschreibungEl = $("textarea#beschreibung");
  var beschreibung = $("textarea#beschreibung").val();
  if(beschreibung == '') {
    error = true;
    beschreibungEl.addClass('is-invalid');
  }

  // Zusammenbau
  var zusammenbauEl = $('input[name="zusammenbau"]');
  var zusammenbau = $('input[name="zusammenbau"]:checked').val();
  if(zusammenbau == undefined) {
    error = true;
    zusammenbauEl.addClass('is-invalid');
  }

  // 360Grad
  var dreihundersechziggradEl = $('input[name="360grad"]');
  var dreihundersechziggrad = $('input[name="360grad"]:checked').val();
  if(dreihundersechziggrad == undefined) {
    error = true;
    dreihundersechziggradEl.addClass('is-invalid');
  }

  // Zweck
  var zweckArray = [];
  var zweck = $('input[name="zweck"]:checked').each(function() {
    zweckArray.push(this.value);
  });
  if(zweckArray.length == 0) {
    error = true;
    $('input[name="zweck"]').addClass('is-invalid');
  }

  // Anmerkungen
  var anmerkungen = $("textarea#anmerkungen").val();

  // Anrede
  var anredeEl = $('input[name="anrede"]');
  var anrede = $('input[name="anrede"]:checked').val();
  if(anrede == undefined) {
    error = true;
    anredeEl.addClass('is-invalid');
  }

  // Vorname
  var vornameEl = $('input[name="vorname"]');
  var vorname = $('input[name="vorname"]').val();
  if(vorname.length == 0) {
    error = true;
    vornameEl.addClass('is-invalid');
  }

  // Nachname
  var nachname = $('input[name="nachname"]').val();
  if(nachname.length == 0) {
    error = true;
    $('input[name="nachname"]').addClass('is-invalid');
  }

  // Firma
  var firma = $('input[name="firma"]').val();
  if(firma.length == 0) {
    $('input[name="firma"]').addClass('is-invalid');
  }

  // E-Mail
  var email = $('input[name="email"]').val();
  if(email.length == 0) {
    $('input[name="email"]').addClass('is-invalid');
  }

  // Telefon
  var telefon = $('input[name="telefon"]').val();
  if(telefon.length == 0) {
    error = true;
    $('input[name="telefon"]').addClass('is-invalid');
  }

  if(error == false) {
    $.post(
      'https://www.probilder.de/form.php', 
      {
        form: 'send',
        produktanzahl: produktanzahl, 
        beschreibung: beschreibung,
        zusammenbau: zusammenbau,
        dreihundersechziggrad: dreihundersechziggrad,
        zweck: zweckArray,
        anmerkungen: anmerkungen,
        anrede: anrede,
        vorname: vorname,
        nachname: nachname,
        firma: firma,
        email: email,
        telefon: telefon
      }, 
      function(response) { 
        if(response == 'success') {
          $('form#formular').remove();
          $('#anfrage').find('.alert-success').removeClass('d-none').show();
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'form_submit',
            'produktanzahl': produktanzahl, 
            'beschreibung': beschreibung,
            'zusammenbau': zusammenbau,
            'dreihundersechziggrad': dreihundersechziggrad,
            'zweck': zweckArray,
          });          
        }
      }
    );

  }

});